import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [activeLink, setActiveLink] = useState('#background');

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const offset = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Offset value in pixels
  
        let currentSection = null;
  
        for (let i = sections.length - 1; i >= 0; i--) {
          const rect = sections[i].getBoundingClientRect();
          if (rect.top <= offset) {
            currentSection = sections[i];
            break;
          }
        }
  
        if (currentSection) {
          setActiveLink(`#${currentSection.id}`);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleClick = (id) => {
        const element = document.querySelector(id);
        const offset = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Offset value in pixels
      
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: 'smooth',
        });
      };

    return (
        <header className='lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24'>

            <div>
                <h1 className='text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl'><Link to="#background" onClick={() => handleClick('#background')}>Maskym Zerkakh</Link></h1>
                <h2 className='mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl'>Front-end Developer</h2>
                <p className='mt-4 max-w-xs leading-normal'>I build accessible, inclusive products and digital experiences for the web.</p>

                <nav id="page-menu" className='nav hidden lg:block' aria-label='In-page jump link'>
                    <ul className='mt-16 pl-6 w-max'>
                        <li><Link to="#background" className={`group flex items-center py-3 ${activeLink === '#background' ? 'active' : ''}`} onClick={() => handleClick('#background')}><span data-number-decoration="001">Background</span></Link></li>
                        <li><Link to="#experience" className={`group flex items-center py-3 ${activeLink === '#experience' ? 'active' : ''}`} onClick={() => handleClick('#experience')}><span data-number-decoration="002">Experience</span></ Link></li>
                        <li><Link to="#skills" className={`group flex items-center py-3 ${activeLink === '#skills' ? 'active' : ''}`} onClick={() => handleClick('#skills')}><span data-number-decoration="003">Skills</span></ Link></li>
                        <li><Link to="#projects" className={`group flex items-center py-3 ${activeLink === '#projects' ? 'active' : ''}`} onClick={() => handleClick('#projects')}><span data-number-decoration="004">Projects</span></ Link></li>
                        {/* <li><Link to="#blog" className={`group flex items-center py-3 ${activeLink === '#blog' ? 'active' : ''}`} onClick={() => handleClick('#blog')}><span data-number-decoration="005">Blog</span></ Link></li> */}
                    </ul>
                </nav>
            </div>

            <ul className='ml-1 mt-8 flex items-center'>
                <li className='mr-5 text-xs'>
                    <a className='block hover:text-slate-200' href="https://github.com/MaksymZerkakh" target='_blank' rel='noreferrer'>
                        <span className='sr-only'>GitHub</span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512" fill='currentColor' className='h-6 w-6' aria-hidden='true'><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
                    </a>
                </li>

                <li className='mr-5 text-xs'>
                    <a className='block hover:text-slate-200' href="https://www.linkedin.com/in/maksym-zerkakh/" target='_blank' rel='noreferrer'>
                        <span className='sr-only'>LinkedIn</span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill='currentColor' className='h-6 w-6' aria-hidden='true'><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                    </a>
                </li>
            </ul>
        </header>
    );
};

export default Header;