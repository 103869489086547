import React from 'react'
import Background from '../sections/Background'
import Experience from '../sections/Experience'
import Projects from '../sections/Projects'
import Skills from '../sections/Skills'
import Footer from '../components/Footer'
import BackToTopButton from './BackToTopButton'

const Main = () => {
  return (
    <main className='pt-24 lg:w-1/2 lg:py-24'>
        <Background />
        <Experience />
        <Skills />
        <Projects />
        <Footer />
        <BackToTopButton />
    </main>
  )
}

export default Main