import React from 'react'

const Background = () => {
  return (
    <section id='background' className='mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24' aria-label='Background'>
        <div className='sticky top-10 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0'>
            <h2 className='text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only'>Background</h2>
        </div>

        <div>
            <p className='mb-4'>I am a proficient front-end developer, specializing in WordPress, Laravel, React, and Next. My expertise lies in crafting robust and dynamic web applications, integrating custom solutions that prioritize optimal user experience and web performance.</p>
            <p className='mb-4'>Moreover, I have a substantial background in SEO from my experience working within a marketing agency environment. Each project is built with an emphasis on website optimization for search engines, aiming to enhance visibility and drive organic traffic. I excel in deadline management and efficient task prioritization. My tenure in marketing agencies has sharpened my communication skills, enabling seamless coordination with clients, designers, and fellow developers. I am proficient in delivering successful projects that align with clients goals and company objectives.</p>
        </div>
    </section>
  )
}

export default Background