import 'tailwindcss/tailwind.css';
import './style.css';
import Header from './components/Header';
import Main from './components/Main';
import TopMenu from './components/TopMenu';
import CustomCursor from './components/CustomCursor';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

function App() {
  const spotlightRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const spotlight = spotlightRef.current;
      if (spotlight) {
        const x = e.clientX;
        const y = e.clientY;
        spotlight.style.left = `${x}px`;
        spotlight.style.top = `${y}px`;
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className='bg-slate-900 leading-relaxed text-slate-400 antialiased selection:bg-indigo-400 selection:text-indigo-900'>
      <Helmet>
        <title>Maksym Zerkakh - Front-end Developer</title>
        <meta name="description" content="Your page description." />
      </Helmet>
      <Router>
        <TopMenu />
      </Router>
      <div className='group/spotlight relative'>
        <div
          ref={spotlightRef}
          className='pointer-events-none fixed inset-0 z-30 transition duration-300'
          style={{
            background:
              'radial-gradient(circle, rgba(29, 78, 216, 0.2) 10%, rgba(29, 78, 216, 0.1) 50%, rgba(29, 78, 216, 0) 70%, transparent 80%)',
            width: '600px',
            height: '600px',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          }}
        ></div>
        <div className='mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0'>
          <div className='lg:flex lg:justify-between lg:gap-4'>
          <Router>
            <Header />
            <Main />
          </Router>
          <CustomCursor />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;