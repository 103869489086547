import React from 'react'

const Footer = () => {
  return (
    <footer class="max-w-md pb-16 text-sm text-slate-500 sm:pb-0">
        <p>
             Built with
            <a href="https://nextjs.org/" class="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300" target="_blank" rel="noreferrer"> Next.js </a>
            and
            <a href="https://tailwindcss.com/" class="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300" target="_blank" rel="noreferrer"> Tailwind CSS </a>, deployed with
            <a href="https://vercel.com/" class="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300" target="_blank" rel="noreferrer"> Vercel </a>.
        </p>
    </footer>
  )
}

export default Footer