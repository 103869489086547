import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const TopMenu = () => {
    const [activeLink, setActiveLink] = useState('#background');

    useEffect(() => {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        let offset = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Offset value in pixels

        if (window.innerWidth < 768) {
          // Adjust offset for mobile devices
          offset -= 100;
        }
  
        let currentSection = null;
  
        for (let i = sections.length - 1; i >= 0; i--) {
          const rect = sections[i].getBoundingClientRect();
          if (rect.top <= offset) {
            currentSection = sections[i];
            break;
          }
        }
  
        if (currentSection) {
          setActiveLink(`#${currentSection.id}`);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleClick = (id) => {
        const element = document.querySelector(id);
        let offset = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Offset value in pixels

        if (window.innerWidth < 768) {
          // Adjust offset for mobile devices
          offset -= 100;
        }
      
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: 'smooth',
        });
      };

  return (
    <nav id="top-menu" className='max-lg:sticky lg:hidden top-0 w-full h-10 bg-slate-900/100 backdrop-blur z-40 shadow-lg' aria-label='In-page jump link'>
        <ol className='fixed flex items-center justify-between w-full h-10 font-bold text-sm'>
            <li className="flex items-center h-full flex-auto"><Link to="#background" className={`relative flex center items-center justify-center h-full w-full px-3 ${activeLink === '#background' ? 'active' : ''}`} onClick={() => handleClick('#background')}><span data-number-decoration="001">Background</span></Link></li>
            <li className="flex items-center h-full flex-auto"><Link to="#experience" className={`relative flex center items-center justify-center h-full w-full px-3 ${activeLink === '#experience' ? 'active' : ''}`} onClick={() => handleClick('#experience')}><span data-number-decoration="002">Experience</span></ Link></li>
            <li className="flex items-center h-full flex-auto"><Link to="#skills" className={`relative flex center items-center justify-center h-full w-full px-3 ${activeLink === '#skills' ? 'active' : ''}`} onClick={() => handleClick('#skills')}><span data-number-decoration="003">Skills</span></ Link></li>
            <li className="flex items-center h-full flex-auto"><Link to="#projects" className={`relative flex center items-center justify-center h-full w-full px-3 ${activeLink === '#projects' ? 'active' : ''}`} onClick={() => handleClick('#projects')}><span data-number-decoration="004">Projects</span></ Link></li>
            {/* <li className="flex items-center h-full flex-auto"><Link to="#blog" className={`relative flex center items-center justify-center h-full w-full px-3 ${activeLink === '#blog' ? 'active' : ''}`} onClick={() => handleClick('#blog')}><span data-number-decoration="005">Blog</span></ Link></li> */}
        </ol>
    </nav>
  )
}

export default TopMenu