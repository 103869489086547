import React from 'react'

const Experience1 = () => {
  return (
    <li className="mb-12">
        <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
            <div className="absolute -inset-x-4 -inset-y-4 z-0 hidden rounded-md transition motion-reduce:transition-none lg:-inset-x-6 lg:block lg:group-hover:bg-slate-800/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"></div>
            <header className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2" aria-label="2018 to Present">2022 — 2024</header>
            <div className="z-10 sm:col-span-6">
                <h3 className="font-medium leading-snug text-slate-200">
                    <div><a className="inline-flex items-baseline font-medium leading-tight text-slate-200 hover:text-indigo-300 focus-visible:text-indigo-300  group/link text-base" href="https://www.superstarcarwashaz.com/" target="_blank" rel="noreferrer" aria-label="Lead Engineer at Upstatement"><span className="absolute -inset-x-4 -inset-y-2.5 hidden rounded md:-inset-x-6 md:-inset-y-4 lg:block"></span><span>Front-end Developer ·<span className="inline-block">Super Star Car Wash<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true"><path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"></path></svg></span></span></a></div>
                    <div>
                        <div className="text-slate-500" aria-hidden="true">Front-end Developer</div>
                    </div>
                </h3>
                <p className="mt-2 text-sm leading-normal">In my role, I have developed, maintained, and deployed production code for our company website, primarily utilizing HTML, PHP, JavaScript, JQuery, and MySQL. I successfully launched a robust WordPress ecosystem that includes synchronized WP Engine Production, Staging, and Development environments, seamlessly integrated with GitHub Actions and Azure DevOps CI/CD Pipelines. This integration has significantly streamlined our update process and improved overall code management efficiency.</p>
                <p className="mt-2 text-sm leading-normal">Furthermore, I played a pivotal role in implementing conversion tracking for our marketing department through Google Tag Manager (GTM). I customized Tags, Triggers, and Variables to enable precise eCommerce tracking, pixel conversions, and cross-domain UTM tracking, leading to substantial improvements in our marketing analytics and campaign optimization efforts. Collaborating closely with designers, project managers, and marketing teams, I created sales landing pages, nurtured campaigns, and ensured SEO optimization to enhance online visibility and engagement. Additionally, I set up A/B testing and integrated analytics tools, fostering a data-driven approach that has significantly boosted our website's effectiveness and conversion rates.</p>
                <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">WordPress</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">PHP</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">JavaScript</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">AJAX</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">RESTful API</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">SCSS</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Git</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">GitHub</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Azure DevOps</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">NPM</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Gulp</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">MySQL</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Postman</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Zsh</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">GTM</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">GA4</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Google Search Console</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Google Insights</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Lighthouse</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Figma</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">AdobeXD</div>
                    </li>
                    <li className="mr-1.5 mt-2">
                        <div className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">Local by Flywheel</div>
                    </li>
                </ul>
            </div>
        </div>
    </li>
  )
}

export default Experience1