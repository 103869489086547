import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        setIsVisible(scrollTop > 300);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Link 
            to="/top" 
            className={`back-to-top ${isVisible ? 'show' : ''} hidden lg:block absolute right-0 bottom-20 transform rotate-90 uppercase text-sm`}
            onClick={scrollToTop}
        >
            Back to Top
        </Link>

        
    );
};

export default BackToTopButton;